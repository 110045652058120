import { Link } from "react-router-dom";

const ServicesLinks = () => {
    return (
        <article id="services-links">
            <h3>Faites confiance à notre équipe d'experts pour tous vos besoins en maçonnerie</h3>
            <div className="content">
                <div className="img-container">

                    <Link to="/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                        <div id="pipes" className="image"></div>
                    </Link>
                    <div className="topic-content">

                        <h4>Drains français</h4>
                        <Link to="/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                            <button>En savoir plus</button>
                        </Link>
                    </div>
                </div>  
                <div className="img-container">

                    <Link to="/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                        <div id="maconnerie" className="image"></div>
                    </Link>
                    <div className="topic-content">

                        <h4>Maçonnerie</h4>
                        <Link to="/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                            <button>En savoir plus</button>
                        </Link>
                    </div>
                </div> 
                <div className="img-container">
                    
                    <Link to="/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                        <div id="cheminees" className="image"></div>
                    </Link>
                    <div className="topic-content">

                        <h4 className="less-margin">Réparation de cheminées</h4>
                        <Link to="/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie">
                            <button>En savoir plus</button>
                        </Link>
                    </div>
                </div>
            </div>
        </article>
    )
};

export default ServicesLinks;