import HeaderServices from "../HeaderServices";

const DrainsFrancais = () => {
    return (
        <>
            <HeaderServices pageName="Drains-Français"/>
            <main>
                
                <article className="services-style">

                    <section className="column">
                        <div className="row">

                            <div className="text-content">
                                <h2>Maçonnerie Pro : installation et réparation de drains français</h2>
                                <p>Votre <strong>drain français</strong> est mal en point et nécessite des réparations ? Vous recherchez une entreprise apte à procéder à l'installation de <strong>drains français</strong> sur des fondations ? Maçonnerie Pro, entreprise spécialisée en maçonnerie résidentielle, commerciale et industrielle sur la Rive-Sud de Montréal, est l'entreprise désignée pour répondre à tous vos besoins en matière de <strong>drains français</strong>.</p>
                            </div>
                        </div>

                        <div className="row resize-mobile">

                            <div className="img" id="construction-plan"></div>
                        </div>
                    </section>

                    <section className="column left-image">

                        <div className="row resize-mobile">

                            <div className="img" id="construction-guy"></div>
                        </div>

                        <div className="row">

                            <div className="text-content">
                                <h2>Pourquoi nous choisir</h2>
                                <p>Possédant plus de 35 ans d'expérience dans le domaine de la maçonnerie et de la réparation de <strong>drains français</strong>, notre équipe de briqueteurs-maçons vous offre des services de réparation de <strong>drains français</strong> de qualité à des prix compétitifs.</p>
                                <p>En faisant appel à nos services pour vérifier, poser ou réparer votre <strong>drain français</strong>, vous bénéficierez de toute l'expertise dont vous aurez besoin pour permettre à votre bâtiment d'être protégé contre les inondations, les moisissures et les autres problèmes reliés à un mauvais fonctionnement de votre <strong>drain français</strong>.</p>
                            </div>
                        </div>
                    </section>

                    <section className="column">

                        <div className="row">

                            <div className="text-content big-text-content">
                                <h2>Réparation de fissures de fondation</h2>
                                <p>Maçonnerie Pro propose également des services de réparation de fissures de fondation. Souvent, lorsque le <strong>drain français</strong> est brisé, la brique et la fondation le sont aussi, donc au lieu de faire appel à plusieurs entreprises pour faire réparer votre <strong>drain français</strong> et votre fondation, vous n'aurez qu'un seul appel à effectuer pour réaliser tous vos travaux.</p>
                                <p>Outre la réparation de <strong>drains français</strong> et de fissures de fondation, Maçonnerie Pro est également reconnue pour son expertise dans les domaines suivants:</p>
                                <ul>
                                    <li>Pose de brique, de ciment et de pierre naturelle (bâtiments neufs et usagés)</li>
                                    <li>Réfection de maçonnerie de fondation et de crépi de finition</li>
                                    <li>Pose de membranes d'étanchéité sur les fondations</li>
                                    <li>Travaux d'excavation variés</li>
                                    <li>Construction et réparation de cheminées en brique et en pierre naturelle</li>
                                </ul>
                                <p>Par conséquent, n'hésitez pas à nous contacter pour tout ce qui concerne la maçonnerie résidentielle, commerciale ou industrielle. Nous avons ce qu'il faut pour répondre à vos besoins. Nous desservons la région de la Rive-Sud de Montréal, St-Hubert, St-Hilaire, Chambly, St-Bruno et La Prairie.</p>
                            </div>
                        </div>

                        <div className="row resize-mobile">

                            <div className="img" id="drain-francais"></div>
                        </div>
                    </section>
                </article>
            </main>
        </>    
    )
};

export default DrainsFrancais;