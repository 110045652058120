import { Link, NavLink } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {

    const [humburgerToggle, setHumburgerToggle] = useState(false);

    const handleClick = () => {

        if(!humburgerToggle) {
            setHumburgerToggle(true);
            document.body.style.overflowY = "hidden";
        }
        else {
            setHumburgerToggle(false);
            document.body.style.overflowY = "visible";
        }
    };

    const [dropdownToggle, setDropdownToggle] = useState(false);

    const mobileDropdown = () => {

        if(!dropdownToggle) {
            setDropdownToggle(true);
        }
        else {
            setDropdownToggle(false);
        }
    };


    return (
        <>
            <nav id="navbar">

                <img src="https://www.maconneriepro.com/images/maconnerie-logo-big.png" alt="Entrepreneur Maçonnerie St-Hubert" ></img>

                <ul id="nav-items-container">
                    <NavLink to="/"><li activeClassName="active">Accueil</li></NavLink>

                    <div className="dropdown">
                        <NavLink to="/services"> <li activeClassName="active">Services<i className="fa fa-caret-down"></i></li></NavLink>
                        <div className="dropdown-content">
                            <ul>
                                <Link to="/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Drains français</li></Link>
                                <Link to="/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Maçonnerie</li></Link>
                                <Link to="/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Réparation de cheminées</li></Link>
                            </ul>
                        </div>
                    </div>

                    <NavLink to="/realisations"><li activeClassName="active">Réalisations</li></NavLink>
                </ul>

                <div id="phone-number">
                    <i className="fa-solid fa-phone"></i>
                    <a href="tel:514-236-2426">514 236 2426</a>
                </div>

                <div id="humburger-menu" onClick={handleClick}>
                    <div id={humburgerToggle ? "open-top" : "close-top"} className="humburger-bars bar1"></div>
                    <div id={humburgerToggle ? "fade-away-middle" : "fade-in-middle"} className="humburger-bars bar2"></div>
                    <div id={humburgerToggle ? "open-bottom" : "close-bottom"} className="humburger-bars bar3"></div>
                </div>

            </nav>



            <div id="blurred-background" className={humburgerToggle ? "open-mobile-navbar" : "close-mobile-navbar"}>

                <div id="mobile-navbar" className={humburgerToggle ? "open-mobile-navbar" : "close-mobile-navbar"}>

                    <div id="phone-number">
                        <a href="tel:514-236-2426">
                            <h3>Contacter nous:</h3>
                            <i className="fa-solid fa-phone"></i>
                            514 236 2426
                        </a>
                    </div>

                    <ul id="mobile-nav-items-container">
                            <NavLink onClick={handleClick} to="/"><li activeClassName="active">Accueil</li></NavLink>

                            <div className="dropdown">
                                <li onClick={mobileDropdown}>Services<i className="fa-solid fa-circle-chevron-down"></i></li>
                                <div id={dropdownToggle ? "open-dropdown" : "close-dropdown"} className="dropdown-content">
                                    <ul>
                                        <NavLink onClick={handleClick} to="/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li activeClassName="active">Drains français</li></NavLink>
                                        <NavLink onClick={handleClick} to="/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li activeClassName="active">Maçonnerie</li></NavLink>
                                        <NavLink onClick={handleClick} to="/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li activeClassName="active">Réparation de cheminées</li></NavLink>
                                    </ul>
                                </div>
                            </div>
                            
                            <NavLink onClick={handleClick} to="/realisations"><li activeClassName="active">Réalisations</li></NavLink>
                    </ul>
                </div>  
            </div>
        </>
        
    );
};

export default Navbar;