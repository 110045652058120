const ValorProposal = () => {
    return (
        <section id="valor-proposal">
            <div id="valor-proposal-container">
                <h3>Pourquoi nous choisir</h3>
                <div className="content">
                    <ul>
                        <li><div className="checkbox"><i className="fa-solid fa-check"></i></div>PRIX COMPÉTITIFS</li>
                        <li><div className="checkbox"><i className="fa-solid fa-check"></i></div>ESTIMATION GRATUITE</li>
                        <li><div className="checkbox"><i className="fa-solid fa-check"></i></div>DÉLAIS RESPECTÉ</li>
                        <li><div className="checkbox"><i className="fa-solid fa-check"></i></div>DYNAMIQUE ET FIABLE!</li>
                    </ul>
                </div>
            </div>
            <figure id="image-container">
                <img src="/images/yellow-helmet.webp" alt="Entrepreneur Maçonnerie St-Hubert"></img>
            </figure>
        </section>
    )
};

export default ValorProposal;