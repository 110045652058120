import HeaderServices from "../HeaderServices";

const Maconnerie = () => {
    return (
        <>
            <HeaderServices pageName="Maçonnerie" />
            <main>

                <article className="services-style">

                    <section className="column left-image">

                            <div className="row resize-mobile">

                                <div className="img" id="bricks"></div>
                            </div>

                            <div className="row">

                                <div className="text-content">
                                    <h2>Maçonnerie résidentielle, commerciale industrielle</h2>
                                    <p>Pour des services de <strong>maçonnerie</strong> résidentielle, commerciale, institutionnelle ou encore industrielle de qualité sur la Rive-Sud de Montréal et ses environs, faites affaire avec Maçonnerie Pro, le spécialiste de la ,<strong>maçonnerie</strong> de tout genre sur les bâtiments neufs et usagés.</p>
                                    <p>En effet, les briqueteurs-maçons de la compagnie, qui possèdent 35 ans d'expérience dans le domaine de la <strong>maçonnerie</strong>, vous promettent des travaux de la plus haute qualité, qui respectent vos échéanciers de livraison et vos attentes.</p>
                                </div>
                            </div>
                    </section>

                    <section className="column">
                        
                        <div className="row">

                            <div className="text-content big-text-content">
                                <h2>Pose et réparation de tous les matériaux</h2>
                                <p>Qu'il s'agisse de pose de pierre synthétique, de pierre naturelle, de brique, de ciment, de ciment acrylique ou de tout autre matériau de construction, nos experts seront en mesure de s'acquitter de vos travaux de <strong>maçonnerie</strong> - installation et réparation - avec brio et minutie.</p>
                                <p>En choisissant Maçonnerie Pro pour prendre en charge vos projets de <strong>maçonnerie</strong> résidentielle ou commerciale, vous bénéficierez non seulement d'une expertise aiguisée, mais vous économiserez également du temps et de l'argent, puisque tous les services de <strong>maçonnerie</strong> sont offerts au même endroit.</p>
                                <br />
                                <p>En outre, notre entreprise propose des services de:</p>
                                <ul>
                                    <li>Réparation de fissures de fondation</li>
                                    <li>Réparation et pose de drains français</li>
                                    <li>Installation de membranes d'étanchéité sur les fondations</li>
                                    <li>Réparation et construction de cheminées</li>
                                    <li>Pose de pavés et de murets</li>
                                    <li>Réfection de balcons et de trottoirs</li>
                                    <li>Et bien plus !</li>
                                </ul>
                                <p>Parmi les avantages de faire affaire avec Maçonnerie Pro pour vos travaux de <strong>maçonnerie</strong>, nous retrouvons :</p>
                                <ul>
                                    <li>Équipe de briqueteurs-maçons dynamique, à l'affût des dernières tendances</li>
                                    <li>Prix compétitifs</li>
                                    <li>Rapidité d'exécution et grande qualité des travaux</li>
                                    <li>Respect des échéanciers de production</li>
                                    <li>Expertise large et variée</li>
                                    <li>Estimation gratuite</li>
                                    <li>Et bien plus !</li>
                                </ul>
                                <p>Autre élément qui nous distingue du lot : nous travaillons autant les bâtiments neufs que sur les constructions existantes. En tant qu'entreprise de <strong>maçonnerie</strong>, nous sommes donc en mesure de répondre à tous vos besoins entourant vos projets.</p>
                                <p>Contactez-nous dès maintenant pour en savoir plus sur nos services. Nous desservons la région de la Rive-Sud de Montréal, St-Hubert, St-Hilaire, Chambly, St-Bruno et La Prairie.</p>
                            </div>
                        </div>

                        <div className="row resize-mobile">

                            <div className="img" id="crack-reparation"></div>
                        </div>
                    </section>
                </article>
            </main>
        </>
    )
}

export default Maconnerie;