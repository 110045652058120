import { Link } from "react-router-dom";

const Services = () => {
    return (
        <article id="services">
            <h3>Tous nos services de maçonnerie</h3>
            <div className="content">
                <div className="row1">
                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/brick-wall-svgrepo-com.svg" alt="Réparation briques St-hubert"/>

                            </div><span>BRIQUES</span>
                        </div>
                    </Link>

                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/fireplace-chimney-svgrepo-com.svg" alt="Réparation cheminée St-hubert"/>

                            </div><span>CHEMINÉES</span></div></Link>

                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/shovel-svgrepo-com.svg" alt="Excavation St-hubert"/>

                            </div><span>EXCAVATION</span>
                        </div>
                    </Link>
                </div>
                <div className="row2">
                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/house-svgrepo-com.svg" alt="Réparation Fondation St-hubert"/>

                            </div><span>FONDATION</span>
                        </div>
                    </Link>

                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/stone-pile-svgrepo-com.svg" alt="Pose de pierres St-hubert"/>

                            </div><span>PIERRES</span>
                        </div>
                    </Link>

                    <Link to="/realisations">
                        <div className="big-icon-container">
                            <div className="icon-container"><img src="/images/stone-path-svgrepo-com.svg" alt="Pose de pavés St-hubert"/>

                            </div><span>PAVÉS</span>
                        </div>
                    </Link>
                </div>  
            </div>
        </article>
        
    )
};

export default Services;