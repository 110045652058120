import Quote from '../Quote';
import Services from '../Services';
import ValorProposal from '../ValorProposal';

const PageAccueil = () => {
    return (
        <main className="main">
            <Quote />
            <Services />
            <ValorProposal />
        </main>
    )
};

export default PageAccueil;