import Header from '../Header';
import ServicesLinks from "../ServicesLinks";

const PageServices = () => {
    return (
        <>
            <Header pageName="Nos Services" pageLinks="Services"/>
            <main>
                <ServicesLinks />
            </main>
        </>
    )
};

export default PageServices;