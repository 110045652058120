import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PageAccueil from './components/pages/PageAccueil';
import PageServices from './components/pages/PageServices';
import PageRealisations from './components/pages/PageRealisations';
import DrainsFrancais from './components/pages/DrainsFrancais';
import Maconnerie from './components/pages/Maconnerie';
import ReparationDeCheminees from './components/pages/ReparationDeCheminees';

import './scss/main.scss';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<PageAccueil />} />
        <Route path="/services" element={<PageServices />} />
        <Route path="/realisations" element={<PageRealisations />} />
        <Route path='/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie' element={<DrainsFrancais />} />
        <Route path='/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie' element={<Maconnerie />} />
        <Route path='/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie' element={<ReparationDeCheminees />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
