const Realisations = () => {
    return (
        <article id="realisations">
            <div className="topic-content">
                <h3>MAÇONNERIE PRO INC est une entreprise spécialisée dans le domaine de la maçonnerie générale</h3>
                <div className="column">
                    <div className="row">
                        <div className="icon-container">
                            <img src="/images/brick-wall-svgrepo-com.svg" alt="Réparation briques St-hubert"/>
                        </div>
                        <h4>Briques</h4>
                        <ul>
                            <li className="less-margin">Remplacement et rejointoiement de briques fissurées</li>
                            <li className="less-margin">Changement d'allèges</li>
                            <li className="less-margin">Pose de solins afin d'éviter l'infiltration d'eau dans les murs</li>
                            <li className="less-margin">Imperméabilisation des briques</li>
                        </ul>
                    </div>
                    <div className="row">    
                        <div className="icon-container">
                            <img src="/images/fireplace-chimney-svgrepo-com.svg" alt="Réparation cheminée St-hubert"/>
                        </div>
                        <h4>Cheminée</h4>
                        <ul>
                            <li>Réparation et remise à neuf</li>
                            <li>Remplacement des tuiles et du courronnement</li>
                            <li>Démolition et reprise des résidus</li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className="row">
                        <div className="icon-container">
                            <img src="/images/shovel-svgrepo-com.svg" alt="Excavation St-hubert"/>
                        </div>
                        <h4>Excavation</h4>
                        <ul>
                            <li>Service de mini-excavatrice</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="icon-container">
                            <img src="/images/house-svgrepo-com.svg" alt="Fondation St-hubert"/>
                        </div>
                        <h4>Fondation</h4>
                        <ul>
                            <li className="less-margin">Réparation de fissure de fondation avec produits spécialisés: membrane, époxy, polymère et ancrages de soutien en métal</li>
                            <li className="less-margin">Pose de drains français ou réparation</li>
                            <li className="less-margin">Imperméabilisation de la fondation</li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className="row">
                        <div className="icon-container">
                            <img src="/images/stone-pile-svgrepo-com.svg" alt="Pose de pierres St-hubert"/>
                        </div>
                        <h4>Pierres</h4>
                        <ul>
                            <li className="less-margin">Pose de pierres naturelles ou modulaires pour trottoir, patio, mur de maison, cheminée, etc.</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="icon-container">
                            <img src="/images/stone-path-svgrepo-com.svg" alt="Pose de pavés St-hubert"/>
                        </div>
                        <h4>Pavés</h4>
                        <ul>
                            <li className="less-margin">Pose de pavés unis, dalles et murets décoratifs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    )
};

export default Realisations;