const Quote = () => {
    return (
        <article id="quote-container">
            <div className="background-filter">
                <div className="content">
                    <h2>Notre mission est de bien répondre aux besoins de nos clients</h2>
                    <a href="tel:514-236-2426">Travaillons ensemble!</a>
                </div>
            </div>
        </article>
    )
};

export default Quote;