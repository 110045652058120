import Header from '../Header';
import Realisations from "../Realisations";

const PageRealisations = () => {
    return (
        <>
            <Header pageName="Nos Réalisations" pageLinks="Réalisations"/>
            <main>
                <Realisations />
            </main>
        </>
    )
};

export default PageRealisations;