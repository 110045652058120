import { useState } from "react";
import { Link } from "react-router-dom"

const Header = (props) => {
    
    const [pageName] = useState(props.pageName);
    const [pageLinks] = useState(props.pageLinks);

    return (
        <header id="header">
            <div className="background-filter">
                <div className="content">
                    <h1>{pageName}</h1>
                    <div className="header-links">
                        <Link to="/">Accueil</Link>
                        <p className="active">{pageLinks}</p>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;