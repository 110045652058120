import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer id="footer">
            <div id="footer-container">
                <div className="background-filter">
                    <div id="company-valor">
                        <img src="/images/maconnerie-logo-big.png" alt="Entrepreneur Maçonnerie St-Hubert"/>
                        <p>Nous sommes une équipe d'experts qui s'est mérité une réputation de confiance grâce à son personnel dynamique.</p>
                    </div>
                    <nav id="company-links">
                        <h4>Liens Utiles</h4>
                        <div className="links">
                            <div className="column1">
                                <ul>
                                    <Link to="/"><li>Accueil</li></Link>
                                    <Link to="/services"><li>Services</li></Link>
                                    <Link to="/realisations"><li>Réalisations</li></Link>
                                </ul>
                            </div>
                            <div className="column2">
                                <ul>
                                    <Link to="/services/drains-francais-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Drains Français</li></Link>
                                    <Link to="/services/maconnerie-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Maçonnerie</li></Link>
                                    <Link to="/services/reparation-de-cheminees-st-hubert-st-hilaire-chambly-st-bruno-la-prairie"><li>Réparation de cheminées</li></Link>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div id="company-contact">
                        <h4>Contact</h4>
                        <div className="phone-container">
                            <i className="fa-solid fa-phone"></i>
                            <a href="tel:514-236-2426">514 236 2426</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="copyright">
                <div id="copyright-part">
                    © MAÇONNERIE PRO 2012. TOUS DROITS RÉSERVÉS.
                </div>
                <div id="creator-part">
                    <a href="mailto:rene.marceau.dev@gmail.com?subject=Maçonnerie Pro Footer Advertisement" target="_top">Créer par René Marceau </a>
                </div>
            </div>
       </footer>     
    )
};

export default Footer;