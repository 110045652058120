import HeaderServices from "../HeaderServices";

const ReparationDeCheminees = () => {
    return (
        <>
            <HeaderServices pageName="Réparation de cheminées" />
            <main>

                <article className="services-style">

                    <section className="column">
                        <div className="row">

                            <div className="text-content">
                                <h2>Réparation de cheminées sur la rive-sud</h2>
                                <p>Votre cheminée est brisée ou a chuté et vous cherchez une entreprise apte à la réparer ? Faites confiance à Maçonnerie Pro, l'expert en <strong>réparation de cheminée</strong> sur la Rive-Sud de Montréal et ses environs.</p>
                                <p>En effet, notre entreprise, qui possède plus de 35 ans d'expérience dans le domaine de la maçonnerie et de la <strong>réparation de cheminée</strong>, est en mesure de procéder à la réparation et à la remise à neuf de tous vos types de cheminée : en brique, en pierre synthétique, en pierre naturelle, etc.</p>
                            </div>
                        </div>

                        <div className="row resize-mobile">

                            <div className="img" id="chimney-sky"></div>
                        </div>
                    </section>

                    <section className="column left-image">

                        <div className="row resize-mobile">

                            <div className="img" id="stone-chimney"></div>
                        </div>

                        <div className="row">

                            <div className="text-content">
                                <h2>Cheminées en pierre naturelle</h2>
                                <p>Nos briqueteurs-maçons sont notamment reconnus pour leur grande aptitude à manier et à tailler la pierre. Vous pouvez donc entièrement leur faire confiance pour procéder à la construction ou à la réparation de votre cheminée en pierre naturelle.</p>
                                <p>Nos services de <strong>réparation de cheminée</strong> sont rapides, abordables et efficaces.</p>
                                <p>Vous pouvez également faire appel à Maçonnerie Pro pour faire inspecter la maçonnerie de votre cheminée si vous doutez de son bon état. Une cheminée fonctionnelle permet de réduire les risques d'incendie, en plus de contribuer à l'environnement en diminuant la pollution.</p>
                            </div>
                        </div>
                    </section>

                    <section className="column">

                        <div className="row">

                            <div className="text-content big-text-content">
                                <h2>Travaux de maçonnerie en tous genres</h2>
                                <p>Outre les services de <strong>réparation de cheminée</strong>, Maçonnerie Pro propose également les services suivants:</p>
                                <ul>
                                    <li>Réparation ou installation de crépi sur vos surfaces</li>
                                    <li>Réparation de fissures de fondation</li>
                                    <li>Installation ou remise à neuf de drains français</li>
                                    <li>Pose de pavés et de murets</li>
                                    <li>Travaux de maçonnerie en tous genres : pose de brique, pierre, de ciment, de ciment acrylique, etc.</li>
                                </ul>
                                <p>Nos briqueteurs-maçons sont spécialisés dans tous les types de ciment. Par conséquent, ils peuvent autant vous prodiguer des services de pose ou de réfection de maçonnerie sur des bâtiments neufs que sur des bâtiments usagés. Avec Maçonnerie Pro, vous avez accès à tous les services de maçonnerie au même endroit.</p>
                                <p>Contactez-nous dès maintenant pour en savoir plus sur nos services. Nos experts desservent la région de la Rive-Sud de Montréal, St-Hubert, St-Hilaire, Chambly, St-Bruno et La Prairie.</p>
                            </div>
                        </div>

                        <div className="row resize-mobile">

                            <div className="img" id="masonry-work"></div>
                        </div>
                    </section>
                </article>
            </main>
        </>
    )
}

export default ReparationDeCheminees;